.tooltip {
	display: inline;
	position: relative;
	z-index: 999;
}

/* Trigger text */

.tooltip-item {
	cursor: pointer;
	display: inline-block;
	font-weight: 700;
	padding: 0 10px;
}

/* Gap filler */

.tooltip-item::after {
	content: '';
	position: absolute;
	width: 360px;
	height: 20px;
	bottom: 100%;
	left: 50%;
	pointer-events: none;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.tooltip:hover .tooltip-item::after {
	pointer-events: auto;
}

/* Tooltip */

.tooltip-content {
	position: absolute;
	z-index: 9999;
	width: 480px;
	left: 50%;
	margin: 0 0 20px -180px;
	bottom: 100%;
	text-align: left;
	font-size: 0.765em;
	line-height: 1.4;
	box-shadow: -5px -5px 15px rgba(48,54,61,0.2);
	background: #2a3035;
	opacity: 0;
	cursor: default;
	pointer-events: none;
}

.tooltip-effect-1 .tooltip-content {
	-webkit-transform: translate3d(0,-10px,0);
	transform: translate3d(0,-10px,0);
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-2 .tooltip-content {
	-webkit-transform-origin: 50% calc(100% + 10px);
	transform-origin: 50% calc(100% + 10px);
	-webkit-transform: perspective(1000px) rotate3d(1,0,0,45deg);
	transform: perspective(1000px) rotate3d(1,0,0,45deg);
	-webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
	transition: opacity 0.2s, transform 0.2s;
}

.tooltip-effect-3 .tooltip-content {
	-webkit-transform: translate3d(0,10px,0) rotate3d(1,1,0,25deg);
	transform: translate3d(0,10px,0) rotate3d(1,1,0,25deg);
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-4 .tooltip-content {
	-webkit-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	-webkit-transform: scale3d(0.7,0.3,1);
	transform: scale3d(0.7,0.3,1);
	-webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
	transition: opacity 0.2s, transform 0.2s;
}

.tooltip-effect-5 .tooltip-content {
	width: 240px;
	margin-left: -120px;
	-webkit-transform-origin: 50% calc(100% + 6em);
	transform-origin: 50% calc(100% + 6em);
	-webkit-transform: rotate3d(0,0,1,15deg);
	transform: rotate3d(0,0,1,15deg);
	-webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
	transition: opacity 0.2s, transform 0.2s;
	-webkit-transition-timing-function: ease, cubic-bezier(.17,.67,.4,1.39);
	transition-timing-function: ease, cubic-bezier(.17,.67,.4,1.39);
}

.tooltip:hover .tooltip-content {
	pointer-events: auto;
	opacity: 1;
	-webkit-transform: translate3d(0,0,0) rotate3d(0,0,0,0);
	transform: translate3d(0,0,0) rotate3d(0,0,0,0);
}

.tooltip.tooltip-effect-2:hover .tooltip-content {
	-webkit-transform: perspective(1000px) rotate3d(1,0,0,0deg);
	transform: perspective(1000px) rotate3d(1,0,0,0deg);
}

/* Arrow */

.tooltip-content::after {
	content: '';
	top: 100%;
	left: 50%;
	border: solid transparent;
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: transparent;
	border-top-color: #2a3035;
	border-width: 10px;
	margin-left: -10px;
}

/* Tooltip content*/

.tooltip-content img {
	position: relative;
	height: 170px;
	display: block;
	float: left;
	margin-right: 1em;
}

.tooltip-text {
	font-size: 1.0em;
	line-height: 1.35;
	display: block;
	padding: 1.31em 1.21em 1.21em 0;
	color: #fff;
}

.tooltip-effect-5 .tooltip-text {
	padding: 1.4em;
}

.tooltip-text a {
	font-weight: bold;
}
